export const HEIGHT = 'height';
export const EMBED_SIZE = 'embed-size';
export const INITIAL_MESSAGE = 'frames-init';
export const AMP_SENTINEL = 'amp';
export const PYM_SENTINEL = 'pym';
export const PYM_REGEX = /xPYMx/;
export const FRAME_PREFIX = 'data-frame-';
export const FRAME_AUTO_INITIALIZED = `${FRAME_PREFIX}auto-initialized`;
export const FRAME_SRC = `${FRAME_PREFIX}src`;
export const FRAME_ATTRIBUTE_PREFIX = `${FRAME_PREFIX}attribute-`;
